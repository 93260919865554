<template>
  <apexchart
    type="line"
    height="200"
    :options="chartOptions"
    :series="series"
    class="audit-chart"
  />
</template>

<script>
const baseChartOptions = {
  chart: {
    width: "100%",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: 3,
    curve: "straight",
  },
  colors: ["#EE6002"],
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 5,
    labels: {
      formatter: (value) => (value ? `${value}%` : value),
    },
  },
  tooltip: {
    custom({
      dataPointIndex,
      w: {
        config: { series },
      },
    }) {
      const [{ data }] = series;
      return (
        "<div class='custom-tooltip'>" +
        `<span><b>${data[dataPointIndex].caseDate}</b></span>` +
        `<div><span>Cases Audited</span><b>${data[dataPointIndex].z}</b></div>` +
        `<div><span>Average Score, %:</span><b>${data[dataPointIndex].y}</b></div>` +
        "</div>"
      );
    },
  },
};

export default {
  name: "AuditLineChart",

  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chartOptions: baseChartOptions,
    };
  },

  computed: {
    series() {
      const data = this.chartData.map((item) => ({
        y: item?.score,
        z: item?.count,
        x: new Date(item?.caseDate).toLocaleDateString(undefined, {
          month: "short",
        }),
        caseDate: new Date(item?.caseDate).toLocaleDateString(undefined, {
          year: "numeric",
          month: "long",
        }),
      }));
      return [{ data }];
    },
  },
};
</script>

<style lang="scss">
.audit-chart {
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;

    div {
      display: flex;
      width: 160px;
      justify-content: space-between;
    }
  }
}
</style>
