<template>
  <section>
    <div class="d-flex justify-start">
      <StatisticItem
        :value="auditsTotal"
        text="Total Cases Audited"
        class="mr-6"
      />
      <StatisticItem
        :value="auditsScore"
        :is-percent="true"
        text="Avarage Audit Score"
      />
    </div>

    <v-card class="reports py-4 px-6 mb-2 mt-4">
      <v-card-title class="pa-0 pb-5">Dynamics</v-card-title>
      <AuditLineChart v-if="auditDynamics.length" :chart-data="auditDynamics" />
      <EmptyBlock v-else />
    </v-card>
  </section>
</template>

<script>
import EmptyBlock from "../EmptyBlock.vue";
import StatisticItem from "../../common/StatisticItem.vue";
import AuditLineChart from "../../common/AuditLineChart.vue";

export default {
  name: "Dynamics",

  components: {
    StatisticItem,
    EmptyBlock,
    AuditLineChart,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    auditsScore() {
      return this.data?.statistic?.score || 0;
    },
    auditsTotal() {
      return this.data?.statistic?.total || 0;
    },
    auditDynamics() {
      return this.data?.data;
    },
  },
};
</script>
