<template>
  <v-card
    :min-width="minWidth"
    :max-width="calculatedMaxWidth"
    class="StatisticItem pa-5 d-flex align-center"
  >
    <span class="mr-4 text-h5 font-weight-bold">
      {{ value }}
      <span v-if="isPercent">%</span>
    </span>
    <v-divider :vertical="true" />
    <span class="ml-4 text-subtitle-1 font-weight-bold text--secondary">
      {{ text }}
    </span>
  </v-card>
</template>

<script>
export default {
  name: "StatisticItem",

  props: {
    text: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: [Number, String],
      default: 100,
    },
    maxWidth: {
      type: [Number, String],
      default: 100,
    },
  },

  computed: {
    calculatedMaxWidth() {
      if (typeof this.maxWidth === "number") {
        const valueSymbolWidth = 15;
        const textSymbolWidth = 8;
        const additionalWidth =
          `${this.value}`.split("").length - 1 + +this.isPercent;
        return (
          this.maxWidth +
          valueSymbolWidth * additionalWidth +
          this.text.length * textSymbolWidth
        );
      }
      return this.maxWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.StatisticItem {
  height: 80px;
  border-radius: 6px;

  .v-divider {
    border-color: var(--v-primary-base);
    border-right-width: 2px;
  }
}
</style>
