<template>
  <v-card class="analytics py-4 px-6 mb-2">
    <v-card-title class="pa-0">Clinician Status</v-card-title>
    <p class="text--secondary mb-6">
      {{ currentDateString }}
    </p>
    <ClinicianBarChart v-if="analyticsData.length" :data="analyticsData" />
    <EmptyBlock v-else />
  </v-card>
</template>

<script>
import ClinicianBarChart from "./ClinicianBarChart.vue";
import EmptyBlock from "../EmptyBlock.vue";
import dateToLocalString from "../../../helpers/dateToLocalString";
import {
  ClinicianStatuses,
  CliniciansStatusColors,
} from "../../../misc/constants";

export default {
  name: "Analytics",

  components: {
    ClinicianBarChart,
    EmptyBlock,
  },

  props: {
    statistics: {
      type: Object,
      required: true,
    },
  },

  data() {
    const statusesItems = Object.values(ClinicianStatuses)
      .filter(
        (category) =>
          category !== ClinicianStatuses.undefined &&
          category !== ClinicianStatuses.deactivated
      )
      .map((status) => {
        const color = CliniciansStatusColors[status];
        return {
          status,
          color,
          text: status,
        };
      });
    const currentDateString = dateToLocalString(new Date());

    return {
      statusesItems,
      currentDateString,
    };
  },

  computed: {
    analyticsData() {
      if (!this.statistics) return [];
      return this.statusesItems.map((item) => {
        const statusPercent = this.statistics[item.status];
        return {
          category: item.text,
          value: statusPercent,
          color: item.color,
        };
      });
    },
  },
};
</script>
