<template>
  <v-main>
    <welcome-items-container />
    <chart-tabs />
  </v-main>
</template>

<script>
import WelcomeItemsContainer from "@/components/home/WelcomeItemsContainer.vue";
import ChartTabs from "@/components/home/ChartTabs.vue";

export default {
  name: "HomePage",

  components: {
    WelcomeItemsContainer,
    ChartTabs,
  },
};
</script>
