import { axiosInstance } from "../setupInterceptors";

const endpointUrl = "/statistics";

export const getCliniciansStatistics = (params) => {
  return axiosInstance
    .get(`${endpointUrl}/clinicians`, { params })
    .then((response) => response.data);
};

export const getHomePageStatistics = (params) => {
  return axiosInstance
    .get(endpointUrl, { params })
    .then((response) => response.data);
};

export const getAuditsDynamics = () => {
  return axiosInstance
    .get(`${endpointUrl}/audits`)
    .then((response) => response.data);
};
