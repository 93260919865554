<template>
  <v-card
    outlined
    flat
    min-height="150"
    class="d-flex flex-column justify-center align-center"
  >
    <v-icon size="40" color="warning">mdi-alert-circle</v-icon>
    <p class="text-h6">Chart data is empty</p>
  </v-card>
</template>

<script>
export default {
  name: "EmptyBlock",
};
</script>
