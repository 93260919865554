<template>
  <v-card class="reports py-4 px-6 mb-2">
    <v-card-title class="pa-0 pb-5">Clinicians Dynamics</v-card-title>
    <TimelineChart
      v-if="chartDataRows.length"
      :data="chartDataRows"
      :chart-height="timelineChartHeight"
      :start-date="timelineStartDate"
    />
    <EmptyBlock v-else />
    <slot name="footer" />
  </v-card>
</template>

<script>
import TimelineChart from "./TimelineChart.vue";
import EmptyBlock from "../EmptyBlock.vue";
import { CliniciansStatusColors } from "../../../misc/constants";

export default {
  name: "Reports",

  components: {
    TimelineChart,
    EmptyBlock,
  },

  props: {
    clinicians: {
      type: Array,
      required: true,
    },
  },

  data() {
    const defaultRowHeight = 37;

    return {
      defaultRowHeight,
    };
  },

  computed: {
    chartDataRows() {
      if (!this.clinicians) return;
      const data = [];
      this.clinicians.forEach(({ name, categoryLogs }) => {
        const clinicianData = categoryLogs.map((log, ind) => {
          const { createdAt, category } = log;
          const startDate = new Date(createdAt).getTime();
          const endDate = categoryLogs[ind + 1]
            ? new Date(categoryLogs[ind + 1].createdAt).getTime()
            : new Date().getTime();
          const color = CliniciansStatusColors[category];

          return {
            name,
            data: [{ x: name, y: [startDate, endDate], fillColor: color }],
          };
        });
        data.push(...clinicianData);
      });
      return data;
    },
    timelineStartDate() {
      let date = new Date().getTime();
      this.clinicians.forEach(({ categoryLogs }) => {
        categoryLogs.forEach(({ createdAt }, ind) => {
          const startDate = new Date(createdAt).getTime();
          if (ind === 0 && date > startDate) {
            date = startDate;
          }
        });
      });
      return date;
    },
    timelineChartHeight() {
      return this.clinicians.length * this.defaultRowHeight + 130;
    },
  },
};
</script>
