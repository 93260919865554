<template>
  <apexchart
    type="rangeBar"
    :height="chartHeight"
    :options="configData"
    :series="data"
  />
</template>

<script>
export default {
  name: "ClinitiansReportsTimeline",

  props: {
    data: {
      type: Array,
      required: true,
    },
    chartHeight: {
      type: Number,
      required: true,
    },
    startDate: {
      type: Number,
      required: true,
    },
  },

  data() {
    const chartOptions = {
      chart: {
        width: "100%",
        type: "rangeBar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          rangeBarGroupRows: true,
          distributed: true,
          borderRadius: 8,
        },
      },
      legend: {
        show: false,
      },
      fill: {
        type: "solid",
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        type: "datetime",
        tickPlacement: "on",
        labels: {
          minHeight: 90,
          rotate: -45,
          format: "dd MMM yyyy",
          rotateAlways: true,
        },
        max: new Date().getTime(),
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },
    };

    return {
      chartOptions,
    };
  },

  computed: {
    configData() {
      return {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          min: this.startDate,
        },
      };
    },
  },
};
</script>
