<template>
  <div
    class="welcome-item d-flex justify-space-between align-center pa-6 rounded-lg"
    :class="itemClass"
    @click="handleItemClick"
  >
    <div
      class="welcome-item__img__container pa-3 rounded-circle"
      :style="{ 'background-color': color }"
    >
      <component :is="icon" class="welcome-item__img" />
    </div>
    <p class="welcome-item__info__text text-body-1 font-weight-bold">
      {{ text }}
    </p>
    <p class="welcome-item__info__amount text-body-1 font-weight-regular">
      {{ amount }}
    </p>
  </div>
</template>

<script>
export default {
  name: "WelcomeItem",

  props: {
    isMovileView: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      required: true,
    },
    amount: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "",
    },
    linkTo: {
      type: String,
      default: "/",
    },
  },

  computed: {
    itemClass() {
      return {
        "mobile-view": this.isMovileView,
        "flex-column": !this.isMovileView,
        "justify-center": !this.isMovileView,
        "justify-start": this.isMovileView,
      };
    },
  },

  methods: {
    handleItemClick() {
      this.$router.push(this.linkTo);
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-item {
  width: 270px;
  height: 180px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: var(--v-background-base);
  cursor: pointer;

  &__img__container {
    width: 56px;
    height: 56px;
  }

  &__info {
    margin-top: 16px;

    &__text {
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
    }

    &__amount {
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &.mobile-view {
    width: 100%;
    height: 78px;

    .welcome-item {
      &__img__container {
        width: 46px;
        height: 46px;
        margin-right: 16px;
      }

      &__info {
        margin-top: 0;

        &__text {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.87);
        }

        &__amount {
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
</style>
