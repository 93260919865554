<template>
  <section class="chart-tabs">
    <div class="tabs-container">
      <v-tabs
        v-model="tab"
        active-class="tabs-container__tab--active"
        center-active
        background-color="#F0F0F0"
        class="app-container"
        @change="onTabsChange"
      >
        <v-tab
          v-for="tabItem in tabsItems"
          :key="tabItem"
          :href="`#${tabItem}`"
          class="text-body-1 text-capitalize font-weight-bold pl-pr-2"
        >
          {{ tabItem }}
        </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="tab" class="app-container mb-6">
      <v-tab-item :key="tabsKeys.analytics" :value="tabsKeys.analytics">
        <v-row v-if="!cliniciansStatistic" class="mt-10">
          <v-col cols="7">
            <v-skeleton-loader type="heading" />
          </v-col>
          <v-col cols="9">
            <v-skeleton-loader type="image" />
          </v-col>
          <v-col cols="3">
            <v-skeleton-loader
              type="sentences"
              :types="{ sentences: 'text@5' }"
            />
          </v-col>
        </v-row>

        <Analytics
          v-if="cliniciansStatistic"
          :statistics="cliniciansStatistic"
          class="mt-10"
        />
      </v-tab-item>

      <v-tab-item :key="tabsKeys.reports" :value="tabsKeys.reports">
        <v-skeleton-loader
          v-if="!clinicians"
          type="table"
          :types="{
            'table-row': 'table-cell@10',
            'table-heading': 'heading',
            'table-thead': '',
          }"
        />
        <div v-if="clinicians">
          <v-row dense class="mt-10">
            <v-col cols="12">
              <Reports :clinicians="clinicians">
                <template #footer>
                  <v-pagination
                    v-if="clinicians.length"
                    :value="pagination.page"
                    :length="paginationLength"
                    color="none"
                    circle
                    class="d-flex justify-start"
                    @input="onPaginationChange"
                  />
                </template>
              </Reports>
            </v-col>
            <v-overlay
              :value="isCliniciansLoading"
              :absolute="true"
              color="white"
              opacity="0.7"
            >
              <v-progress-circular size="70" indeterminate color="primary" />
            </v-overlay>
          </v-row>
        </div>
      </v-tab-item>

      <v-tab-item :key="tabsKeys.dynamic" :value="tabsKeys.dynamic">
        <v-row v-if="!dynamics" class="mt-10">
          <v-col cols="7">
            <v-skeleton-loader type="heading" />
          </v-col>
          <v-col cols="9">
            <v-skeleton-loader type="image" />
          </v-col>
          <v-col cols="3">
            <v-skeleton-loader
              type="sentences"
              :types="{ sentences: 'text@5' }"
            />
          </v-col>
        </v-row>

        <Dynamics v-if="dynamics" :data="dynamics" class="mt-10" />
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import {
  getCliniciansStatistics,
  getAuditsDynamics,
} from "../../services/statistics";
import { getClinicians } from "../../services/users";
import Analytics from "./analytics/Analytics.vue";
import Reports from "./reports/Reports.vue";
import Dynamics from "./dynamics/Dynamics.vue";

export default {
  name: "ChartTabs",

  components: {
    Analytics,
    Reports,
    Dynamics,
  },

  data() {
    const tabsKeys = {
      dynamic: "Audit Score",
      analytics: "Clinicians Status",
      reports: "Clinicians Dynamics",
    };

    const tabsItems = Object.values(tabsKeys);

    return {
      tab: tabsKeys.dynamic,
      tabsItems,
      tabsKeys,
      cliniciansStatistic: null,
      clinicians: null,
      dynamics: null,
      isCliniciansLoading: false,
      pagination: {
        pageSize: 10,
        page: 1,
        total: 0,
      },
    };
  },

  computed: {
    paginationLength() {
      return Math.ceil(this.pagination.total / this.pagination.pageSize);
    },
  },

  mounted() {
    this.fetchTabData();
  },

  methods: {
    onTabsChange(value) {
      this.tab = value;
      this.fetchTabData();
    },
    fetchTabData() {
      switch (this.tab) {
        case this.tabsKeys.analytics:
          return this.getClinicianStatistics();
        case this.tabsKeys.reports:
          return this.getClinicians();
        case this.tabsKeys.dynamic:
          return this.getDynamics();
      }
    },
    async getClinicianStatistics() {
      try {
        this.cliniciansStatistic = await getCliniciansStatistics();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch statistics error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    async getClinicians() {
      try {
        this.isCliniciansLoading = true;
        const query = this.buildQuery();
        const { data, total } = await getClinicians(query);
        this.clinicians = data;
        this.pagination.total = total;
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.isCliniciansLoading = false;
      }
    },
    async getDynamics() {
      try {
        this.dynamics = await getAuditsDynamics();
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.isCliniciansLoading = false;
      }
    },
    buildQuery() {
      const queryParams = {};
      const { page, pageSize } = this.pagination;

      queryParams.page = page || 1;
      queryParams.pageSize = pageSize || 10;
      queryParams.populate = "categoryLogs";

      const filters = {
        "categoryLogs.0": { $exists: true },
      };

      queryParams.filter = filters;

      return queryParams;
    },
    async onPaginationChange(value) {
      this.pagination.page = value;
      await this.getClinicians();
    },
  },
};
</script>

<style lang="scss">
.chart-tabs {
  .tabs-container {
    background-color: #f0f0f0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &__tab--active {
      color: var(--v-text-black-base);
    }
  }

  .v-pagination {
    &__navigation {
      box-shadow: none;
    }

    &__item {
      box-shadow: none;

      &--active {
        background: #f0f0f0 !important;
        color: var(--v-text-black-base) !important;
      }
    }
  }
}
</style>
